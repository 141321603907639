:root {
  --theHeight: 100vh;
}

.App {
  text-align: center;
  background-color: rgb(13, 14, 14);
}

.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.hello {
  --animate-duration: 5000ms;
}

.SecondView {
  height: var(--theHeight);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
